// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import GoogleCalendarSync from "../../blocks/googlecalendarsync/src/GoogleCalendarSync";
import Cfringfencepatients1 from "../../blocks/cfringfencepatients1/src/Cfringfencepatients1";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfiptracking1 from "../../blocks/cfiptracking1/src/Cfiptracking1";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Polling from "../../blocks/polling/src/Polling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Cfcloudflaredomainmapping1 from "../../blocks/cfcloudflaredomainmapping1/src/Cfcloudflaredomainmapping1";
import Cfcustomnotetemplatescreator1 from "../../blocks/cfcustomnotetemplatescreator1/src/Cfcustomnotetemplatescreator1";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import WebLandingPage from "../../blocks/landingpage/src/LandingPage.web";
import NeedHelp from "../../blocks/scheduling/src/NeedHelp.web";
import ScheduledCallback from "../../blocks/scheduling/src/ScheduledCallback.web";
import CareTeam from "../../blocks/scheduling/src/CareTeam.web";
import WellnessMemebershipDetails from "../../blocks/landingpage/src/WellnessMemebershipDetails.web";
import WellnessMembershipPlan from "../../blocks/landingpage/src/WellnessMembershipPlan.web";
import WebWellnessLandingPage from "../../blocks/landingpage/src/WellnessLandingPage.web";
import WellnessJourneyUpdate from "../../blocks/cfcustomonboardingflowcreator1/src/WellnessJourneyUpdate.web";
import SaveProgress from "../../blocks/cfcustomonboardingflowcreator1/src/SaveProgress.web";
import WellnessJourney from "../../blocks/cfcustomonboardingflowcreator1/src/WellnessJourney.web";
import Analytics from "../../blocks/analytics/src/Analytics";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Cfmicrosoftdefenderforcloud6 from "../../blocks/cfmicrosoftdefenderforcloud6/src/Cfmicrosoftdefenderforcloud6";
import Cfringfencetherapists1 from "../../blocks/cfringfencetherapists1/src/Cfringfencetherapists1";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Cfnonstandardinfrasetup1 from "../../blocks/cfnonstandardinfrasetup1/src/Cfnonstandardinfrasetup1";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Cfemaillistener1 from "../../blocks/cfemaillistener1/src/Cfemaillistener1";
import DesktopNotifications from "../../blocks/desktopnotifications/src/DesktopNotifications";
import VideoLibrary from "../../blocks/videolibrary/src/VideoLibrary";
import Cfcontacthistory1 from "../../blocks/cfcontacthistory1/src/Cfcontacthistory1";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Cfautomateddataencryption1 from "../../blocks/cfautomateddataencryption1/src/Cfautomateddataencryption1";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Videos from "../../blocks/videos/src/Videos";
import Splitpayments2 from "../../blocks/splitpayments2/src/Splitpayments2";
import Cfcustomroleandpermissioncreator1 from "../../blocks/cfcustomroleandpermissioncreator1/src/Cfcustomroleandpermissioncreator1";
import Cfbulkbillingsuperinvoices1 from "../../blocks/cfbulkbillingsuperinvoices1/src/Cfbulkbillingsuperinvoices1";
import Cfattendancetracking1 from "../../blocks/cfattendancetracking1/src/Cfattendancetracking1";
import Cfcallqueueing1 from "../../blocks/cfcallqueueing1/src/Cfcallqueueing1";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Cfemailevents1 from "../../blocks/cfemailevents1/src/Cfemailevents1";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Cftaskevents1 from "../../blocks/cftaskevents1/src/Cftaskevents1";
import DragDropInterface from "../../blocks/dragdropinterface/src/DragDropInterface";
import Cfchatbotlistener1 from "../../blocks/cfchatbotlistener1/src/Cfchatbotlistener1";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Hashtags from "../../blocks/hashtags/src/Hashtags";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Cfcontactpropertiesevents1 from "../../blocks/cfcontactpropertiesevents1/src/Cfcontactpropertiesevents1";
import Cfdisablescreenshots1 from "../../blocks/cfdisablescreenshots1/src/Cfdisablescreenshots1";
import Cfchatbotevents1 from "../../blocks/cfchatbotevents1/src/Cfchatbotevents1";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import ICalendarSupport from "../../blocks/icalendarsupport/src/ICalendarSupport";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Cftasklistener1 from "../../blocks/cftasklistener1/src/Cftasklistener1";
import Cfsmsevents1 from "../../blocks/cfsmsevents1/src/Cfsmsevents1";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Gamification from "../../blocks/educationalgamification/src/Gamification";
import Cfcustomonboardingflowcreator1 from "../../blocks/cfcustomonboardingflowcreator1/src/Cfcustomonboardingflowcreator1.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Cfsmslistener1 from "../../blocks/cfsmslistener1/src/Cfsmslistener1";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Sorting from "../../blocks/sorting/src/Sorting";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Cfbrandedinterfacedocuments1 from "../../blocks/cfbrandedinterfacedocuments1/src/Cfbrandedinterfacedocuments1";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Cfconditionallogic1 from "../../blocks/cfconditionallogic1/src/Cfconditionallogic1";
import Cftelephonylistener1 from "../../blocks/cftelephonylistener1/src/Cftelephonylistener1";
import Cfcontactlists1 from "../../blocks/cfcontactlists1/src/Cfcontactlists1";
import Cfactivityconsole1 from "../../blocks/cfactivityconsole1/src/Cfactivityconsole1";
import ViewChat from "../../blocks/chat/src/ViewChat";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsEdit from '../../blocks/termsconditions/src/TermsConditionsEdit.web';
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Cfcoursemodules1 from "../../blocks/cfcoursemodules1/src/Cfcoursemodules1";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Cfmasksensitivefields1 from "../../blocks/cfmasksensitivefields1/src/Cfmasksensitivefields1";
import Cfaccesslevelmatrix1 from "../../blocks/cfaccesslevelmatrix1/src/Cfaccesslevelmatrix1";
import Share from "../../blocks/share/src/Share";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Cfbillingcodeoverridestacking1 from "../../blocks/cfbillingcodeoverridestacking1/src/Cfbillingcodeoverridestacking1";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import Cfcontactpropertieslistener1 from "../../blocks/cfcontactpropertieslistener1/src/Cfcontactpropertieslistener1";
import Cfutmtracking1 from "../../blocks/cfutmtracking1/src/Cfutmtracking1";
import Cfonboardingjumpin1 from "../../blocks/cfonboardingjumpin1/src/Cfonboardingjumpin1";
import DailyScheduleNotifications from "../../blocks/dailyschedulenotifications/src/DailyScheduleNotifications";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import Cfwhitelabelurl1 from "../../blocks/cfwhitelabelurl1/src/Cfwhitelabelurl1";
import Cfdirectbilling1 from "../../blocks/cfdirectbilling1/src/Cfdirectbilling1";
import Cfbrandedsingupportal1 from "../../blocks/cfbrandedsingupportal1/src/Cfbrandedsingupportal1";
import Cftelephonyevents1 from "../../blocks/cftelephonyevents1/src/Cftelephonyevents1";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import PatientDetails from '../../blocks/email-account-registration/src/therapyregistration/PatientDetails.web';
import PatientDetailsUpdate from '../../blocks/email-account-registration/src/therapyregistration/PatientDetailsUpdate.web'
import HealthReview from '../../blocks/email-account-registration/src/HealthReview.web';
import AuthorisedRepresentative from '../../blocks/email-account-registration/src/therapyregistration/AuthorizedRepresentative.web';
import AuthorizedRepresentativeUpdate from '../../blocks/email-account-registration/src/therapyregistration/AuthorizedRepresentativeUpdate.web'
import ThanksForSharingPage from '../../blocks/email-account-registration/src/ThanksForSharingPage.web';
import SupportDesign from '../../blocks/email-account-registration/src/SupportDesign.web';
import LastQuestion from '../../blocks/email-account-registration/src/LastQuestion.web';
import FlowDecision from '../../blocks/email-account-registration/src/FlowDecision.web';
import CustomForm from '../../blocks/customform/src/CustomForm.web';
import Providers from '../../blocks/customform/src/Providers.web';
import SelectedProvider from '../../blocks/customform/src/SelectedProvider.web';
import CheckInsurance from '../../blocks/customform/src/CheckInsurance.web';
import AilmentsPage from '../../blocks/email-account-registration/src/AilmentsPage.web';
import InsuranceValidation from '../../blocks/customform/src/InsuranceValidation.web';
import InsuranceVerify from '../../blocks/customform/src/InsuranceVerify.web';
import ScheduledSession from '../../blocks/scheduling/src/ScheduledSession.web'

const routeMap = {
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
GoogleCalendarSync:{
 component:GoogleCalendarSync,
path:"/GoogleCalendarSync"},
Cfringfencepatients1:{
 component:Cfringfencepatients1,
path:"/Cfringfencepatients1"},
SaveAsPdf:{
 component:SaveAsPdf,
path:"/SaveAsPdf"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Cfiptracking1:{
 component:Cfiptracking1,
path:"/Cfiptracking1"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Polling:{
 component:Polling,
path:"/Polling"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Cfcloudflaredomainmapping1:{
 component:Cfcloudflaredomainmapping1,
path:"/Cfcloudflaredomainmapping1"},
Cfcustomnotetemplatescreator1:{
 component:Cfcustomnotetemplatescreator1,
path:"/Cfcustomnotetemplatescreator1"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Cfmicrosoftdefenderforcloud6:{
 component:Cfmicrosoftdefenderforcloud6,
path:"/Cfmicrosoftdefenderforcloud6"},
Cfringfencetherapists1:{
 component:Cfringfencetherapists1,
path:"/Cfringfencetherapists1"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Cfnonstandardinfrasetup1:{
 component:Cfnonstandardinfrasetup1,
path:"/Cfnonstandardinfrasetup1"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Cfemaillistener1:{
 component:Cfemaillistener1,
path:"/Cfemaillistener1"},
DesktopNotifications:{
 component:DesktopNotifications,
path:"/DesktopNotifications"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
Cfcontacthistory1:{
 component:Cfcontacthistory1,
path:"/Cfcontacthistory1"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Cfautomateddataencryption1:{
 component:Cfautomateddataencryption1,
path:"/Cfautomateddataencryption1"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Videos:{
 component:Videos,
path:"/Videos"},
Splitpayments2:{
 component:Splitpayments2,
path:"/Splitpayments2"},
Cfcustomroleandpermissioncreator1:{
 component:Cfcustomroleandpermissioncreator1,
path:"/Cfcustomroleandpermissioncreator1"},
Cfbulkbillingsuperinvoices1:{
 component:Cfbulkbillingsuperinvoices1,
path:"/Cfbulkbillingsuperinvoices1"},
Cfattendancetracking1:{
 component:Cfattendancetracking1,
path:"/Cfattendancetracking1"},
Cfcallqueueing1:{
 component:Cfcallqueueing1,
path:"/Cfcallqueueing1"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Cfemailevents1:{
 component:Cfemailevents1,
path:"/Cfemailevents1"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Cftaskevents1:{
 component:Cftaskevents1,
path:"/Cftaskevents1"},
DragDropInterface:{
 component:DragDropInterface,
path:"/DragDropInterface"},
Cfchatbotlistener1:{
 component:Cfchatbotlistener1,
path:"/Cfchatbotlistener1"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
Cfcontactpropertiesevents1:{
 component:Cfcontactpropertiesevents1,
path:"/Cfcontactpropertiesevents1"},
Cfdisablescreenshots1:{
 component:Cfdisablescreenshots1,
path:"/Cfdisablescreenshots1"},
Cfchatbotevents1:{
 component:Cfchatbotevents1,
path:"/Cfchatbotevents1"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
ICalendarSupport:{
 component:ICalendarSupport,
path:"/ICalendarSupport"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Cftasklistener1:{
 component:Cftasklistener1,
path:"/Cftasklistener1"},
Cfsmsevents1:{
 component:Cfsmsevents1,
path:"/Cfsmsevents1"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
match_making_template:{
 component:Cfcustomonboardingflowcreator1,
path:"/match_making_template/:navigationBarTitleText"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Cfsmslistener1:{
 component:Cfsmslistener1,
path:"/Cfsmslistener1"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
// GroupVideoCall:{
//  component:GroupVideoCall,
// path:"/GroupVideoCall"},
Cfbrandedinterfacedocuments1:{
 component:Cfbrandedinterfacedocuments1,
path:"/Cfbrandedinterfacedocuments1"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Cfconditionallogic1:{
 component:Cfconditionallogic1,
path:"/Cfconditionallogic1"},
Cftelephonylistener1:{
 component:Cftelephonylistener1,
path:"/Cftelephonylistener1"},
Cfcontactlists1:{
 component:Cfcontactlists1,
path:"/Cfcontactlists1"},
Cfactivityconsole1:{
 component:Cfactivityconsole1,
path:"/Cfactivityconsole1"},
// Chat:{
//  component:Chat,
// path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsEdit:{
 component:TermsConditionsEdit,
 path : "/TermsAndConditions"
},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Cfcoursemodules1:{
 component:Cfcoursemodules1,
path:"/Cfcoursemodules1"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Cfmasksensitivefields1:{
 component:Cfmasksensitivefields1,
path:"/Cfmasksensitivefields1"},
Cfaccesslevelmatrix1:{
 component:Cfaccesslevelmatrix1,
path:"/Cfaccesslevelmatrix1"},
Share:{
 component:Share,
path:"/Share"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Cfbillingcodeoverridestacking1:{
 component:Cfbillingcodeoverridestacking1,
path:"/Cfbillingcodeoverridestacking1"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
Cfcontactpropertieslistener1:{
 component:Cfcontactpropertieslistener1,
path:"/Cfcontactpropertieslistener1"},
Cfutmtracking1:{
 component:Cfutmtracking1,
path:"/Cfutmtracking1"},
Cfonboardingjumpin1:{
 component:Cfonboardingjumpin1,
path:"/Cfonboardingjumpin1"},
DailyScheduleNotifications:{
 component:DailyScheduleNotifications,
path:"/DailyScheduleNotifications"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
Cfwhitelabelurl1:{
 component:Cfwhitelabelurl1,
path:"/Cfwhitelabelurl1"},
Cfdirectbilling1:{
 component:Cfdirectbilling1,
path:"/Cfdirectbilling1"},
Cfbrandedsingupportal1:{
 component:Cfbrandedsingupportal1,
path:"/Cfbrandedsingupportal1"},
Cftelephonyevents1:{
 component:Cftelephonyevents1,
path:"/Cftelephonyevents1"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  dual_landing_pages: {
    component: WebLandingPage,
    path: '/dual_landing_pages/:navigationBarTitleText?',
    exact: true,
  },
  WellnessMembershipPlan: {
    component: WellnessMembershipPlan,
    path: '/wellnessMembershipPlan',
    exact: true,
  },
  WellnessMemebershipDetails: {
    component: WellnessMemebershipDetails,
    path: '/WellnessMemebershipDetails/:navigationBarTitleText',
    exact: true,
  },
  single_service_landing_pages: {
    component: WebWellnessLandingPage,
    path: '/single_service_landing_pages/:navigationBarTitleText',
    exact: true,
  },
  needHelp:{
    component:NeedHelp,
    path:'/NeedHelp',
    exact:true
  },
  scheduledCallback: {
    component:ScheduledCallback,
    path:'/scheduledCallback',
    exact:true
  },
  CareTeam: {
    component:CareTeam,
    path:'/CareTeam',
    exact:true
  },
  WellnessJourney:{
    component: WellnessJourney,
    path :"/signup_form_template/:navigationBarTitleText",
    exact:"true"
  },
  WellnessJourneyUpdate:{
    component:WellnessJourneyUpdate,
    path:"/wellnessJourneyUpdate/:navigationBarTitleText",
    exact:"true"
  },

    SaveProgress:{
      component : SaveProgress,
      path : "/saveProgress",
      exact:true
    },
    InfoPage: {
      component: InfoPage,
      path: '/InfoPage',
    },
  Alert: {
    component: AlertBlock,
    path: "*/Alert",
    modal: true
  },  
  AuthorisedRepresentative: {
    component: AuthorisedRepresentative,
    path: '/AuthorisedRepresentative',
    exact: true
  },
  AuthorizedRepresentativeUpdate: {
    component: AuthorizedRepresentativeUpdate,
    path: '/AuthorizedRepresentativeUpdate/:navigationBarTitleText',
    exact: true
  },
  PatientDetails: {
    component: PatientDetails,
    path: '/PatientDetails',
    exact: true
  },
  PatientDetailsUpdate:{
    component : PatientDetailsUpdate,
    path:"/PatientDetailsUpdate/:navigationBarTitleText",
    exact:true
  },
  HealthReview : {
    component : HealthReview,
    path : '/HealthReview',
    exact : true
  },
  Ailments:{
    component:AilmentsPage,
    path : '/Ailments',
    exact : true
  },
  SupportDesign:{
    component : SupportDesign,
    path: '/SupportDesign',
    exact: true
  },
  LastQuestion:{
    component : LastQuestion,
    path: '/LastQuestion',
    exact: true
  },
  FlowDecision:{
    component : FlowDecision,
    path: '/FlowDecision',
    exact: true
  },   
  CustomForm:{
    component : CustomForm,
    path: '/CustomForm',
    exact: true
  },
 
  CheckInsurance:{
    component : CheckInsurance,
    path: '/CheckInsurance/:navigationBarTitleText',
    exact: true
  }, 
  InsuranceValidation:{
    component : InsuranceValidation,
    path: '/InsuranceValidation',
    exact: true
  },
  Providers: {
    component: Providers,
    path: '/Providers',
    exact: true
  },  
  SelectedProvider:{
    component : SelectedProvider,
    path: '/SelectedProvider',
    exact: true
  },
  InsuranceVerify: {
    component : InsuranceVerify,
    path: '/InsuranceVerify',
    exact: true
  },
  ThanksForSharing: {
    component: ThanksForSharingPage,
    path: '/ThanksForSharing',
    exact: true
  },
  ScheduledSession: {
    component: ScheduledSession,
    path: '/ScheduledSession',
    exact: true
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100%' }}>
          {/* <TopNav /> */}
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;